<template>
	<div>
		<p class="step_tit">③ Google OTP 인증번호</p>
		<div class="bright_input">
			<input
				v-model="gogle_otp_certfc_number"
				maxlength="6"
				type="text" placeholder="6자리 인증번호 입력"
			>
		</div>


		<div class="btn_area">
			<button
				class="btn_l btn_fill_blue"
				:disabled="gogle_otp_certfc_number.length != 6"

				@click="postOtp"
			>확인</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'test_otp_confirm'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: 'OTP 인증 테스트'
					, not_header: true
					, not_footer: true
				}
				, gogle_otp_certfc_number: ''
			}
		}

		, methods: {
			postOtp: async function(){
				this.$log.console('postOtp')
				try{
					this.$bus.$emit('on', true)
					if(!this.gogle_otp_certfc_number){
						throw 'OTP 번호를 입력하세요'
					}
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_google_otp
						, data: {
							gogle_otp_certfc_number: this.gogle_otp_certfc_number
						}
						, type: true
					})

					if(result.success){
						this.is_pin = true
					}else{
						throw result.message
					}

				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify',  { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$emit('onLoad', this.program)
		}
	}
</script>